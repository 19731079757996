import React from 'react';
import './politica-de-privacidade.css';
import Seo from '../components/Seo';
import Layout from '../components/layout';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';

const PrivacyPolicy = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/politica-de-privacidade', label: 'Política de Privacidade' }
  ];

  return (
    <Layout>
      <div className="privacyPolicy-container">
        <Seo
          title="Política de Privacidade | Prontuário Fácil"
          description="Política de Privacidade do Prontuário Fácil. Saiba como coletamos, usamos e protegemos seus dados." // Descrição da página
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="privacyPolicy-content">
          <h1>Política de Privacidade</h1>
          <section>
            <h2>1. Informações Gerais</h2>
            <p>Esta Política de Privacidade contém informações sobre coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes do nosso site, com o objetivo de demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.</p>
          </section>

          <section>
            <h2>2. Coleta de Dados</h2>
            <p>Explicar aqui como e por que os dados pessoais são coletados. Informar os tipos de dados coletados.</p>
          </section>

          <section>
            <h2>3. Uso dos Dados</h2>
            <p>Descrever como os dados coletados são usados, incluindo as finalidades.</p>
          </section>

          <section>
            <h2>4. Armazenamento e Segurança dos Dados</h2>
            <p>Detalhar as medidas de segurança implementadas para proteger os dados dos usuários e o tempo de retenção dos dados.</p>
          </section>

          <section>
            <h2>5. Compartilhamento de Dados</h2>
            <p>Informar com quem os dados podem ser compartilhados (ex: parceiros comerciais, autoridades governamentais, etc).</p>
          </section>

          <section>
            <h2>6. Direitos do Usuário</h2>
            <p>Explicar os direitos dos usuários em relação aos seus dados pessoais (como direito de acesso, correção, exclusão, etc).</p>
          </section>

          <section>
            <h2>7. Mudanças na Política de Privacidade</h2>
            <p>Informar como e quando a política de privacidade pode ser atualizada.</p>
          </section>

          <section>
            <h2>8. Contato</h2>
            <p>Oferecer uma maneira para os usuários entrarem em contato caso tenham perguntas sobre a política de privacidade.</p>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
